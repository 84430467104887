import { useQuery, FetchResult } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { useIntercom } from 'react-use-intercom'
import { useRouter } from 'next/router'

import { useMutation } from 'hooks/useMutation'

import GET_USER_SESSION from './graphql/GetUserSession.graphql'
import { GetUserSession } from './graphql/__generated__/GetUserSession'

import CREATE_USER_SESSION_MUTATION from './graphql/CreateUserSessionMutation.graphql'
import {
  CreateUserSessionMutation,
  CreateUserSessionMutationVariables
} from './graphql/__generated__/CreateUserSessionMutation'

import DESTROY_USER_SESSION_MUTATION from './graphql/DestroyUserSessionMutation.graphql'
import {
  DestroyUserSessionMutation,
  DestroyUserSessionMutationVariables
} from './graphql/__generated__/DestroyUserSessionMutation'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CreateSessionResult = FetchResult<CreateUserSessionMutation, Record<string, any>, Record<string, any>>

// Re-export these types so they are easier to consume
export {
  type GetUserSession_currentUser as IUser,
  type GetUserSession_currentAccount as IAccount,
  type GetUserSession_currentAccount_buyer as IBuyer,
  type GetUserSession_currentAccount_seller as ISeller,
  type GetUserSession_currentAccount_rep as IRep
} from './graphql/__generated__/GetUserSession'
import { AccountableTypeEnum, UserSignupSourceEnum } from '../../__generated__/globalTypes'

import { setSentryUser, clearSentryUser } from 'lib/helpers/sentry'
import redirect from 'lib/universal-redirect'
import { axiosInstance } from 'services/Axios'

function enforceTerms() {
  redirect({ href: '/updated-terms' })
}

let redirecting = false

function redirectByAccountType(accountType: AccountableTypeEnum | undefined | null, refreshPage = false) {
  // Just incase re-render of hook causes double-redirect
  if (redirecting) return

  switch (accountType) {
    case AccountableTypeEnum.BUYER:
      redirect({ href: '/', refreshPage })
      break
    case AccountableTypeEnum.REP:
      redirect({
        href: `/rh/dashboard`,
        refreshPage
      })
      break
    case AccountableTypeEnum.SELLER:
      redirect({
        href: `/sh/dashboard`,
        refreshPage
      })
      break
    default:
      // User has not created any account yet, send to homepage
      redirect({ href: '/' })
  }

  redirecting = true
}

function redirectBySignupSource(signupSource: UserSignupSourceEnum | undefined | null, refreshPage = false) {
  switch (signupSource) {
    case UserSignupSourceEnum.BUYER_INVITE:
    case UserSignupSourceEnum.BUYER:
      redirect({ href: '/create-account/buyer', refreshPage })
      break
    case UserSignupSourceEnum.REP:
      redirect({ href: '/create-account/sales-rep/preview' })
      break
    case UserSignupSourceEnum.SELLER:
      redirect({ href: '/create-account/wholesaler', refreshPage })
      break
    default:
      // User has not created any account yet, send to homepage
      redirect({ href: '/' })
  }
}

interface IAccount {
  id: string
  type: AccountableTypeEnum
  accountableId: string
}

interface HandleNewSessionProps {
  accounts?: IAccount[] | null
  currentUser?: {
    id: string
    termsAccepted: boolean
  } | null
}

type EndSessionProps = {
  redirectTo?: string
  reloadPage?: boolean
  onError?: (error?: Error) => void
}

type FindCurrentAccountProps = {
  accounts?: IAccount[]
}

// The current account id for the current session, from the local session cookie
export const getSessionAccountId = () => {
  const cookies = axiosInstance.getCookies()
  const value = cookies.get('session_account_id')
  const sessionAccountId = value ? value.toString() : null

  return sessionAccountId
}

let preferredAccountType: AccountableTypeEnum | undefined = undefined
let sessionTerminated = false

export function useUserSession() {
  /*
   * NOTES:
   *
   * - The Apollo cache is basically being used here for global state management
   *   for user session data.
   *
   *   This works, because we're not really updating the data very often, if at
   *   all. So most of the time this hook is called, it should just return the
   *   data straight from the cache.
   *
   *   If we needed to update the user session data regularly, then we'd be
   *   better off using React context, or state management library like Redux.
   *
   *   If the session data does need to be updated, it can be updated directly
   *   in the Apollo cache.
   */
  const { data, loading, error, refetch: refetchUserSession } = useQuery<GetUserSession>(GET_USER_SESSION)
  const { shutdown: clearIntercomSession } = useIntercom()
  const router = useRouter()

  // Set current account id in a local session cookie for the current session
  const setSessionAccountId = useCallback((accountId?: string) => {
    if (typeof window !== 'undefined') {
      const cookies = new Cookies()
      cookies.set('session_account_id', accountId, {
        path: '/',
        domain: `.${process.env.NEXT_PUBLIC_DOMAIN}`
      })
    }

    saveSessionAccountId(accountId)
  }, [])

  const [sessionAccountId, saveSessionAccountId] = useState<string | undefined>(getSessionAccountId())

  const setPreferredAccountType = useCallback((accountType: AccountableTypeEnum | undefined) => {
    preferredAccountType = accountType
  }, [])

  /*
   * Authenticates user credentials with backend. If valid, a backend session cookie will be returned
   *
   * NOTE: not the same as "session account id" cookie
   */
  const [createSession] = useMutation<CreateUserSessionMutation, CreateUserSessionMutationVariables>(
    CREATE_USER_SESSION_MUTATION,
    {
      toastOptions: {
        defaultErrorMessage: 'Failed to login'
      },
      onCompleted: ({ createUserSession }) => {
        const availableAccounts = createUserSession?.accounts || accounts
        const newAccount = findCurrentAccount({ accounts: availableAccounts })

        // Set the session account cookie, so if we refresh the page, it will use the correct account
        // It's important that this cookie is set _before_ GET_USER_SESSION is refetched, otherwise `currentAccount` won't be populated
        if (newAccount) {
          setSessionAccountId(newAccount.id)
        }

        refetchUserSession()
      }
    }
  )

  const [destroySession] = useMutation<DestroyUserSessionMutation, DestroyUserSessionMutationVariables>(
    DESTROY_USER_SESSION_MUTATION,
    {
      refetchQueries: [GET_USER_SESSION],
      toastOptions: {
        defaultErrorMessage: 'Failed to logout'
      }
    }
  )

  const { isGuest = true, currentUser, accounts, currentAccount } = data ?? {}
  const { isBuyer, isSeller, isRep } = currentAccount ?? {}

  const redirectToDestination = useCallback(
    (accountType?: AccountableTypeEnum) => {
      const { query } = router
      const cookies = new Cookies()

      // If returnTo query param is present, redirect to that page
      if (query.returnTo) {
        const refreshPage = String(query.returnTo).includes('//app.')
        // Clear cookies for returnTo if set
        cookies.remove('returnTo', { path: '/', domain: `.${process.env.NEXT_PUBLIC_DOMAIN}` })
        redirect({ href: query.returnTo.toString(), refreshPage })

        // Otherwise if returnTo cookie is present, redirect to that page
      } else if (cookies.get('returnTo')) {
        const returnTo = cookies.get('returnTo')
        cookies.remove('returnTo', { path: '/', domain: `.${process.env.NEXT_PUBLIC_DOMAIN}` })
        redirect({ href: returnTo.toString() })

        // Otherwise, just redirect back to whatever homepage for for the account type (buyer-hub, seller-hub or rep-hub)
      } else {
        redirectByAccountType(accountType, true)
      }
    },
    [router]
  )

  /*
   * positiveCondition: if false, redirect to unauthorizedUrl
   * unauthorizedUrl: url to redirect to if positiveCondition is false, usually the login page
   * recordReturnTo: if true, record the current page url to cookies for redirect after login
   * returnToIfOK: if true, redirect to returnTo url if it exists, and positiveCondition is true
   */
  const securityCheck = useCallback(
    ({
      positiveCondition,
      unauthorizedUrl,
      recordReturnTo = true,
      returnToIfOK = true
    }: {
      positiveCondition: boolean
      unauthorizedUrl: string
      recordReturnTo?: boolean
      returnToIfOK?: boolean
    }) => {
      const cookies = new Cookies()
      const returnToUrl = cookies.get('returnTo')
      const currentPath = router.asPath

      // The point of this, is so if user logs out, we don't start redirecting to
      // some other page whilst document.location.href redirect is in process (see
      // endSession)
      if (sessionTerminated) return false

      if (positiveCondition) {
        // Redirect using 'returnTo' cookies, if exists
        if (returnToIfOK && returnToUrl) {
          cookies.remove('returnTo', { path: '/', domain: `.${process.env.NEXT_PUBLIC_DOMAIN}` })
          redirect({ href: returnToUrl })
          return false
        }
      } else {
        // Store URL to cookies in anticipation for redirect after login
        //
        // Don't store unless guest, i.e. don't want to record the returnTo if it's a buyer trying to access a seller page
        if (isGuest && recordReturnTo && !returnToUrl && currentPath !== '/') {
          cookies.set('returnTo', currentPath, {
            path: '/',
            domain: `.${process.env.NEXT_PUBLIC_DOMAIN}`
          })
        }
        redirect({ href: unauthorizedUrl })
        return false
      }

      return true
    },
    [router.asPath, isGuest]
  )

  const endSession = useCallback(
    async ({ redirectTo, reloadPage = false, onError }: EndSessionProps = {}) => {
      const result = await destroySession({
        variables: { input: {} },
        onError: error => {
          onError?.(error)
        }
      })

      if (!result.data?.destroyUserSession?.success) {
        return
      }

      setSessionAccountId(undefined)

      if (typeof window !== 'undefined') {
        const cookies = new Cookies()
        cookies.remove('session_account_id', {
          path: '/',
          domain: `.${process.env.NEXT_PUBLIC_DOMAIN}`
        })
        // Clear cookies to show again the promotions banner on next login
        cookies.remove('dismiss_available_promotions_banner')
      }

      clearSentryUser()
      clearIntercomSession()
      sessionTerminated = true

      if (redirectTo) {
        document.location.href = redirectTo
      } else if (reloadPage) {
        document.location.reload()
      }
    },
    [clearIntercomSession, destroySession, setSessionAccountId]
  )

  /*
   * Works out what account the user wants or last used, based on what accounts
   * are actually available
   */
  const findCurrentAccount = useCallback(
    ({ accounts }: FindCurrentAccountProps) => {
      const cookies = new Cookies()
      const preferredAccount = preferredAccountType && accounts?.find(account => account.type === preferredAccountType)
      const lastSelectedAccountId = preferredAccount?.id || cookies.get('last_selected_account_id')
      const lastSelectedAccount = accounts?.find(account => account.id === lastSelectedAccountId)

      if (accounts && accounts.length > 0) {
        let accountId = lastSelectedAccount ? lastSelectedAccountId : currentAccount?.id

        // If the user only has one account, then just use that account.
        //
        // Otherwise, use the lastSelectedAccount as per cookie set when selecting
        // an account
        if (!accountId && accounts.length === 1) {
          accountId = accounts[0].id
        }

        if (accountId) {
          return accounts?.find(account => account.id === accountId)
        }

        // TODO: update currentAccount in cache
      }
    },
    [currentAccount?.id]
  )

  /*
   * This function can be called after starting a new session, and it will appropriately handle a few next steps:
   *
   * - Switches user to the last selected account if they have multiple accounts
   * - Redirects user to the terms page if they have not accepted the terms
   * - Redirects user to the appropriate dashboard based on their account type
   * - Redirects user to the account selection page if they have multiple accounts, and no last selected account is found
   */
  const handleNewSession = useCallback(
    ({ accounts: thisAccounts, currentUser: thisUser }: HandleNewSessionProps = {}) => {
      const availableAccounts = thisAccounts || accounts
      const user = thisUser || currentUser
      const newAccount = findCurrentAccount({ accounts: availableAccounts })

      // Redirect user to accept terms, if they haven't already
      if (user && !user.termsAccepted) {
        enforceTerms()
        return
      }

      const { query } = router
      const cookies = new Cookies()

      // If we know where the user was trying to go, redirect them there
      if (query.returnTo || cookies.get('returnTo')) {
        redirectToDestination(newAccount?.type)
      }
      // Otherwise, guess where they need to go based on their accounts and current account type
      else {
        if ((availableAccounts?.length || 0) > 1) {
          if (newAccount) {
            // Multi-type user, has previous account selection in cookie, redirect them to the account they last used
            redirectByAccountType(newAccount.type, true)
          } else {
            // Multi-type user, let them choose their account if no last selected account is found
            redirect({ href: '/accounts' })
          }
        } else {
          redirectToDestination(newAccount?.type)
        }
      }

      return newAccount
    },
    [accounts, redirectToDestination, currentUser, findCurrentAccount, router]
  )

  /*
   * Assumes the user is already logged in. Just switches the session account
   * cookies, controlling which of their user accounts is currently active
   */
  const switchAccount = useCallback(
    (accountId: number | string, redirectUrl?: string) => {
      setSessionAccountId(accountId.toString())
      document.location.href = redirectUrl || document.location.href
    },
    [setSessionAccountId]
  )

  // Set Sentry user context if there is a current user
  useEffect(() => {
    if (currentUser != null) {
      setSentryUser(currentUser)
    }
  }, [currentUser])

  return {
    // Session management functions
    createSession,
    endSession,
    handleNewSession,
    refetchUserSession,
    switchAccount,

    redirectByAccountType,
    redirectBySignupSource,
    redirectToDestination,
    redirecting,
    securityCheck,
    enforceTerms,

    // "session account" id
    sessionAccountId,
    getSessionAccountId,
    setSessionAccountId,
    setPreferredAccountType,

    // GraphQL query state
    sessionLoading: loading,
    sessionError: error,

    // GraphQL query data
    isGuest,
    isBuyer,
    isSeller,
    isRep,
    accounts,
    currentAccount,
    currentUser
  }
}

export default useUserSession
